import React, { useState } from "react";
import styled from "styled-components";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import Grid from "../components/layout/Grid";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Paywall from "../components/Paywall";
import SEO from "../components/seo";
import SiteHeader from "../components/SiteHeader";
import colors from "../components/style/colors";
import H3 from "../components/typography/H3";
import P1 from "../components/typography/P1";
import P2 from "../components/typography/P2";
import Button from "../components/ui/Button";
import PopupWrapper from "../components/ui/popupWrapper";
import "../main.css";
import H1 from "../components/typography/H1";

const StyledGrid = styled(Grid)`
  max-width: 40em;
  margin: 2.5em auto;
`;

const PricingCard = styled.div`
  text-align: center;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
  max-width: 100%;
  cursor: pointer;
  background: linear-gradient(${colors.accent1_gradient});
  :last-child {
    background: linear-gradient(${colors.accent2_gradient});
  }
  padding: 1.5em;
  margin: 1em auto;
  border-radius: 0.6em;
  h3 {
    margin: 0 0 0.2em;
  }
  p {
    margin: 0;
  }
`;

const PricingPage = props => {
  const [paywallVisibility, setPaywallVisibility] = useState(false);

  return (
    <>
      <CookieBanner />
      <LayoutWrapper>
        <SEO
          title=""
          description="Learn all about the pricing of Boxy Suite for Mac."
          canonical={props.location.href}
        />
        <PopupWrapper
          show={paywallVisibility}
          onOutsideClick={() => {
            setPaywallVisibility(false);
          }}
        >
          <Paywall setPaywallVisibility={setPaywallVisibility} />
        </PopupWrapper>

        <SiteHeader dark />
        <LayoutSection small center>
          <H1 secondary>Boxy Suite Pricing</H1>
          <P2>Boxy Suite has a simple pricing with no hidden costs.</P2>
          <PricingCard
            gradient={2}
            onClick={() => {
              setPaywallVisibility(true);
            }}
          >
            {/* <P1 color="white" /> */}
            <H3 color="white">
              $39<span style={{ fontSize: "0.8em", color: "rgba(255,255,255,.6)" }}>/year</span>
            </H3>
          </PricingCard>
          <P2 style={{ marginBottom: "2rem" }}>Pricing might change based on your location.</P2>
          <Button
            small
            onClick={() => {
              setPaywallVisibility(true);
            }}
          >
            Try it for free
          </Button>
        </LayoutSection>
        <Footer legal />
      </LayoutWrapper>
    </>
  );
};

export default PricingPage;
